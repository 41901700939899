'use client';

import React from 'react';

import {
  ErrorBoundary as SentryErrorBoundary,
  ErrorBoundaryProps
} from '@sentry/nextjs';

const Fallback = () => (
  <div className='flex flex-col items-center justify-center h-screen text-center'>
    <h1 className='text-2xl font-semibold'>Something went wrong</h1>
    <p className='text-red-500 text-sm'>Please try refreshing the page or contact support.</p>
  </div>
);

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ fallback = <Fallback />, ...props }) => {
  return <SentryErrorBoundary fallback={fallback} {...props} />;
};

export default ErrorBoundary;
